import HorizonAutumn from "../images/HorizonAutumn.jpg";
import JulianJackFelix from "../images/JulianJackFelix.jpg";
import VenmoQRCode from "../images/VenmoQRCode.png";
import ZelleQRCode from "../images/ZelleQRCode.png";

export default function Support() {
  return (
    <div>
      <main className="page-header">
        <h2 >Support the Horizon Ensemble</h2>
      </main>
      <div style={{backgroundColor: "white"}}>
        <img width='100%' src={HorizonAutumn}/>
      </div>
      <p style={{fontSize: "21px", margin: "10vh"}}>
        Thank you for supporting the Horizon Ensemble! We are proud to be <br/>supporting young composers, building community among musicians <br/>and audiences, and making beautiful music for all to enjoy. Your <br/>contributions make this possible, and help us greatly with costs related <br/>to concert production, administration, and more!
      </p>
      <body className='outreach' style={{flexDirection: "column", marginBottom: '0%'}}>
        <div style={{margin: '10vh'}}>
          <h3><u><strong>Donate here!</strong></u></h3>
          <p style={{fontSize: "21px"}}>The Horizon Ensemble is a 501(c) nonprofit. Therefore, all donations are tax-<br/>deductible and will go towards our musical and community-oriented goals.<br/>
This December, thanks to a generous <strong>matching gift,</strong> <br/>
your contributions up to $1,000 will be <strong>twice as effective.</strong><br/>
 Be a part of our inaugural Season of Giving by supporting our mission today!<br/>
You can donate right here on this page.</p>
        </div>
        <script src="https://donorbox.org/widget.js" paypalExpress="false"></script>
        <iframe src="https://donorbox.org/embed/horizon-ensemble-donation-form?language=en" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" style={{maxWidth:'500px', minWidth: '250px', maxHeight:'none!important', marginRight: '0px'}} allow="payment"></iframe>
      </body>
      <body className="outreach" style={{backgroundColor: "#C8E6F7"}}>
        <div style={{margin: '10vh'}}>
        <h3><u><strong>Other Contribution Methods</strong></u></h3>
        <p style={{fontSize: "21px"}}>You can also find us on financial platforms such as:</p>
         <body style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center"}}>
          <div style={{margin: '10vh'}}>
            <h3><strong>Venmo</strong></h3>
            <p style={{fontSize: "21px"}}>On Venmo: @HorizonEnsemble<br/>Scan the QR code below to find us!</p>
            <img width='300px' height='300px' src={VenmoQRCode}/>
          </div>
          <div style={{margin: '10vh'}}>
            <h3><strong>Zelle</strong></h3>
            <p style={{fontSize: "21px"}}>On Zelle: Horizon Ensemble, Inc.<br/>Scan the QR code below to find us!</p>
            <img width='300px' height='300px' src={ZelleQRCode}/>
          </div>
          </body>
          <h3><strong>By Mail</strong></h3>
        <p style={{fontSize: "21px"}}>The Horizon Ensemble can accept checks. Please make <br/>checks payable to Horizon Ensemble and send to:<br/>
        <br/>Horizon Ensemble
        <br/>67 Newbury St.
        <br/>Boston, MA 02116</p>
        </div>
      </body>
      <body className='outreach'>
        <div style={{margin: '10vh'}}>
          <h3><u><strong>Impact</strong></u></h3>
          <p style={{fontSize: "24px"}}>Thanks to you, the Horizon Ensemble has been able to organize:</p>
          <body className='outreach'>
          <img width='50%' src={JulianJackFelix}/>
          <p style={{fontSize: "21px", margin: '10vh'}}>
            <ul style={{textAlign: 'left'}}>
              <li>14 full-length concerts of chamber and orchestral music from friends & from history</li>
              <li>15 live premieres of newly created works by young living composers</li>
              <li>Our very first <u>Call for Scores</u> (ongoing!)
             </li>
            </ul>
            And we’ve collaborated with:
            <ul style={{textAlign: 'left'}}>
              <br/>
              <li>23 composer friends</li>
              <li>9 young instrumental & vocal soloists</li>
              <li>Over 150 instrumentalists of the Boston-area community</li>
              <li>2 poets</li>
              <li>2 community organizations (with more to come!)</li>
            </ul>
          </p>
          </body>
          <p style={{fontSize:'26px'}}>With your support, we can continue and expand our work of advocating <br/>for young artists and bringing lively music to our communities!</p>
        </div>
      </body>
  </div>
  );
}